body {
    background-color: #FAFAFA;
}

.header {
    width:100%;
    height: 110px;
    margin: 0 auto;
    position: relative;
    background: url("../../images/hero-background.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-bottom-left-radius: 75% 25%;
    border-bottom: 7px solid #98C221;
}