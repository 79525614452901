body {
    background-color: #FAFAFA;
}

.header {
    width:100%;
    height: 110px;
    margin: 0 auto;
    position: relative;
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("../../images/hero-background.jpg");
    border-bottom-left-radius: 75% 25%;
    border-bottom: 7px solid #98C221;
}

.text-container {
    width: 80%;
}

.text-container p {
    font-size: 12px;
}

.video-card {
    box-shadow: 10px 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    border-radius: 8px;
}

.video {
    border-radius: 8px;
}

