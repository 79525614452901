body {
    background-color: #FAFAFA;
}

.video-section .rodal-dialog {
    display: flex;
    height: auto !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: transparent;
    font-family: inherit;
    font-size: 1rem;
    padding: 1.25em;
    border-radius: 0.3125em;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative !important;
    z-index: 1100;
}

.video-image {
    box-shadow: 10px 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    border-radius: 8px;
}

.news-card {
    height: 500px;
    box-shadow: 10px 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    border-radius: 8px;
}

.news-card-link, .news-card-link:hover, .news-card-link:link, .news-card-link:visited, .news-card-link:active {
    text-decoration: none !important;
    color: #212121;
}

animated-box {
    margin: 50px;
    width: 250px;
    height: 300px;
    border-radius: 10px;
    background-image: linear-gradient(to bottom right, #98C221, #1B5E20);
}

.feature-title {
    height: 2.6em;
}

.feature-text {
    height: 3em;
    line-height: 1em;
    overflow: hidden;
}

.feature-image {
    max-height: 100px;
}