body {
    background-color: #FAFAFA;
}

.header {
    width:100%;
    height: 110px;
    margin: 0 auto;
    position: relative;
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("../../images/hero-background.jpg");
    border-bottom-left-radius: 75% 25%;
    border-bottom: 7px solid #98C221;
}

.timeline-element .vertical-timeline-element-content {
    background-color: #ececec;
    box-shadow: 0 4px 2px 0 #98C221;
}

.vertical-timeline::before {
    background-color: #98C221;
}

.vertical-timeline-element-icon {
    box-shadow: 0 0 0 4px #98C221, inset 0 2px 0 rgba(0,0,0,.08), 0 3px 0 4px rgba(0,0,0,.05);
}

.vertical-timeline-element-content .vertical-timeline-element-date {
    margin-right: 0;
}

.vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content .vertical-timeline-element-date, .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content .vertical-timeline-element-date {
    margin-left: 0;
}

.news-card, .news-card:hover, .news-card:link, .news-card:visited, .news-card:active {
    text-decoration: none !important;
    color: #212121;
}

@media screen and (max-width: 540px) {
    .vertical-timeline-element-date {
        text-align: center;
        font-weight: bold !important;
    }

    .vertical-timeline-element-content::before {
        border-right-color: #ececec;
    }

    .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content::before, .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content::before {
        border-left-color: #ececec;
    }
}

/* For Tablets */
@media screen and (min-width: 540px) and (max-width: 1170px) {
    .vertical-timeline-element-date {
        text-align: center;
        font-weight: bold !important;
    }

    .vertical-timeline-element-content::before {
        border-right-color: #ececec;
    }

    .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content::before, .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content::before {
        border-left-color: #ececec;
    }
}

@media screen and (min-width: 1170px) {
    .vertical-timeline-element-date {
        text-align: center !important;
        font-weight: bold !important;
        font-size: 1.2em !important;
        margin-top: -6px;
    }

    .vertical-timeline-element-content::before {
        border-left-color: #ececec;
    }

    .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content::before, .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content::before {
        border-right-color: #ececec;
    }
}