body {
    background-color: #FAFAFA;
}

.header {
    width:100%;
    height: 110px;
    margin: 0 auto;
    position: relative;
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("../../images/hero-background.jpg");
    border-bottom-left-radius: 75% 25%;
    border-bottom: 7px solid #98C221;
}

.animated-box {
    /*background-color: #white;*/
    margin: 50px;
    width: 250px;
    height: 300px;
    border-radius: 10px;
    /*border: 2px solid #98C221;*/
    background-image: linear-gradient(to bottom right, #98C221, #1B5E20);
}

.download-button {
    background-color: #FAFAFA;
}

.download-button:hover {
    background-color: #FAFAFA;
    color: #212121;
}

.download-button:focus {
    background-color: #FAFAFA;
    color: #212121;
    box-shadow: none;
}