
.info-box {
    background-color: #FAFAFA;
    box-shadow: 10px 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    border-radius: 8px;
}

.info-card {
    background-color: #e9e9e9;
    box-shadow: 10px 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    border-radius: 8px;
    cursor: pointer;
}

.mail-link {
    color: #212121;
    text-decoration: none;
}

.mail-link:hover {
    color: #98C221;
}