.divider {
    opacity: 0.2;
}

.togglable-div {
    overflow: hidden;
    margin-top: 15px;
}

.social-link {
    color: #FAFAFA;
}

.social-link div {
    color: #FAFAFA;
}

.subscribe-button {
    background-color: #212121;
    color: #98C221;
}

.subscribe-button:hover {
    background-color: #98C221;
    color: #212121;
}

.subscribe-button:focus {
    background-color: #98C221;
    color: #212121;
    box-shadow: none;
}

.subscribe-button:active {
    background-color: #98C221;
    color: #212121;
    box-shadow: none;
}

.link {
    text-decoration: none;
}

.link:hover {
    text-decoration: none;
}

.mail-link {
    color: #BDBDBD;
    text-decoration: none;
}

.mail-link:hover {
    color: #98C221;
}

.rodal,
.rodal-mask {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: fixed !important;
    padding: 10px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.5);
    overflow-x: hidden;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.rodal-dialog {
    display: flex;
    /*margin: auto;*/
    height: auto !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #fff;
    font-family: inherit;
    font-size: 1rem;
    padding: 1.25em;
    border-radius: 0.3125em;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative !important;
    z-index: 1100;
}