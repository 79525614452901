.navbar {
    -webkit-transition: background-color 0.5s ease-out;
    -moz-transition: background-color 0.5s ease-out;
    -o-transition: background-color 0.5s ease-out;
    transition: background-color 0.5s ease-out;
}

.fixed-top {
    z-index: 100;
}

.bordered{
    border-bottom: 1px solid rgba(255,255,255,.2) !important;
}

.hamburger {
    border: none;
    outline: none;
    background: none;
}

.hamburger:focus {
    border: none;
    outline: none;
    background: none;
}

.hamburger-dark {
    background-color: #636363 !important;
}

.hamburger-dark:after {
    background-color: #636363 !important;
}

.hamburger-dark:before {
    background-color: #636363 !important;
}

.hamburger-light {
    background-color: #FAFAFA !important;
}

.hamburger-light:after {
    background-color: #FAFAFA !important;
}

.hamburger-light:before {
    background-color: #FAFAFA !important;
}

.navitem {
    background-color: darkblue;
}

.menu-item {
    color: #FAFAFA;
    text-align: center;
    margin: 5px;
}

.menu-item-expanded {
    color: #FAFAFA;
    text-align: center;
    font-size: 16pt;
    margin-top: 8px;
}

.active {
    color: #98C221 !important;
}

.menu-item:hover {
    color: #98C221;
    text-decoration:none;
}

.navbar-light {
    background-color: #FAFAFA;
}

.navbar-light .navbar-menu-items .menu-item {
    color: #636363;
}

.navbar-light .navbar-menu-items .menu-item:hover {
    color: #98C221;
}

.navbar-brand {
    margin-left: 7%;
}

.navbar-menu-items {
    margin-right: 7%;
    margin-left: 7%;
}